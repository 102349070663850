<template>
  <!-- begin::Card -->
  <div class="card card-custom gutter-b">
    <div class="card-header flex-nowrap border-0 pt-6 pb-0">
      <div class="card-title">
        <h3 class="card-label">Proceso de matrícula</h3>
      </div>
      <div class="card-toolbar"></div>
    </div>
    <div class="card-body">
      <!-- begin::Filter -->
      <v-row class="mb-5">
        <v-col>
          <v-autocomplete
            v-model="search"
            :disabled="areStudentsLoading || !students.length"
            :filter="customFilter"
            :items="students"
            :loading="areStudentsLoading"
            clearable
            filled
            hide-details
            item-text="code"
            item-value="code"
            label="Buscar estudiante por código ó nombre"
            rounded
            @change="getStudent()"
          >
            <!-- begin::selected student item -->
            <template v-slot:selection="{ item, index }">
              <v-chip color="primary" small v-if="index === 0">
                <span>{{ item.full_name }}</span>
              </v-chip>
            </template>
            <!-- end::selected student item -->
            <template v-slot:item="{ item, index }">
              <v-list-item-content>
                <v-list-item-title>
                  <span class="font-weight-bold">
                    {{ item.code }}
                  </span>
                  -
                  <span class="text-uppercase font-weight-medium">
                    {{ item.full_name }}
                  </span>
                </v-list-item-title>
                <v-divider
                  v-if="index < filteredStudent.length - 1"
                  class=""
                ></v-divider>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
      <!-- end::Filter -->

      <!-- begin:: Información de estudiante si hay un estudiante seleccionado -->
      <v-scroll-y-reverse-transition
        :hide-on-leave="true"
        :leave-absolute="true"
      >
        <div v-if="showStepper">
          <v-container>
            <!-- begin::content card title -->
            <v-row>
              <v-col>
                <p class="text-h5 font-weight-medium">
                  Verificación de matrícula
                </p>
              </v-col>
            </v-row>
            <!-- end::content card title -->

            <!-- begin::Student Info card -->
            <v-card class="mb-7 rounded-lg">
              <v-scroll-y-reverse-transition
                :hide-on-leave="true"
                :leave-absolute="true"
              >
                <!-- begin:: fallback si se esta obteniendo la información del estudiante -->
                <div v-if="isLoadingStudentInfo" class="pa-5 my-4">
                  <v-row class="d-flex align-center flex-column">
                    <p class="text-h5 font-weight-bold mb-3">
                      Detalles de estudiante
                    </p>
                    <p class="text-subtitle font-weight-bold mb-3">
                      Cargando información...
                    </p>

                    <v-col cols="6">
                      <v-progress-linear
                        color="blue darken-1"
                        height="6"
                        indeterminate
                        rounded
                      ></v-progress-linear>
                    </v-col>
                  </v-row>
                </div>
              </v-scroll-y-reverse-transition>
              <!-- end:: fallback si se esta obteniendo la información del estudiante -->

              <!-- begin:: Información del estudiante -->
              <v-scroll-y-reverse-transition
                :hide-on-leave="true"
                :leave-absolute="true"
              >
                <div v-if="!isLoadingStudentInfo">
                  <p class="text-h5 font-weight-bold mb-2 pt-4 pl-4">
                    Información de estudiante
                  </p>

                  <v-container>
                    <v-row>
                      <v-col>
                        <div class="black--text mb-1 flex-column">
                          <div class="text-body-2">Código</div>
                          <span
                            class="text-h6 font-weight-medium text-capitalize"
                            >{{ student.code }}</span
                          >
                        </div>
                      </v-col>
                      <v-col>
                        <div class="black--text mb-1 flex-column">
                          <div class="text-body-2">Nombres</div>
                          <span
                            class="text-h6 font-weight-medium text-capitalize"
                            >{{ student.first_name }}</span
                          >
                        </div>
                      </v-col>
                      <v-col>
                        <div class="black--text mb-1 flex-column">
                          <div class="text-body-2">Apellidos</div>
                          <span
                            class="text-h6 font-weight-medium text-capitalize"
                            >{{ student.last_name }}</span
                          >
                        </div>
                      </v-col>
                      <v-col>
                        <div class="black--text mb-1 flex-column">
                          <div class="text-body-2">Grado que cursará</div>
                          <span class="text-h6 font-weight-medium">{{
                            student.grade
                          }}</span>
                        </div>
                      </v-col>
                      <v-col>
                        <div class="black--text mb-1 flex-column">
                          <div class="text-body-2">Especialidad a cursar</div>
                          <span class="text-h6 font-weight-medium">{{
                            student.speciality
                          }}</span>
                        </div>
                      </v-col>
                    </v-row>
                  </v-container>
                </div>
              </v-scroll-y-reverse-transition>
              <!-- end:: Información del estudiante -->
            </v-card>
            <!-- end::Student Info card -->

            <!-- begin::Steps -->

            <v-stepper class="rounded-lg" v-model="activeState">
              <v-stepper-header>
                <v-stepper-step
                  :complete="activeState > 1"
                  :editable="
                    existInArray(
                      'Verificar información del sostenedor económico y pagos',
                      currentPageActions
                    )
                  "
                  step="1"
                >
                  Verificación de datos
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step
                  :complete="activeState > 2"
                  :editable="
                    existInArray(
                      'Verificar e imprimir documentos',
                      currentPageActions
                    )
                  "
                  step="2"
                >
                  Verificación de documentos
                </v-stepper-step>
              </v-stepper-header>

              <v-stepper-items>
                <v-stepper-content step="1">
                  <!-- begin::step content -->

                  <!-- begin:: Indicaciones del procedimiento a realizar por las personas designadas -->
                  <v-row v-if="isNewStudent">
                    <!-- Indicaciones si es nuevo ingreso -->
                    <v-col>
                      <h2>Indicaciones</h2>
                      <ul>
                        <li>
                          La información del sostenedor económico debe coincidir
                          con la de uno de los familiares registrados del
                          estudiante. Si no coincide, es necesario acudir a la
                          administración correspondiente.
                        </li>
                        <li>
                          Verificar que el nombre del grado y la especialidad a
                          cursar sea correcto.
                        </li>
                        <li>
                          Comprobar que se haya completado el pago de la reserva
                          y el complemento, o únicamente el pago de la
                          matrícula, así como el pago del cursillo.
                        </li>
                        <li>
                          Si falta algún pago, es necesario proceder al paso 2.
                        </li>
                      </ul>
                    </v-col>
                  </v-row>
                  <v-row v-else>
                    <!-- Indicaciones si es antiguo ingreso -->
                    <v-col>
                      <h2>Indicaciones</h2>
                      <ul>
                        <li>
                          La información del sostenedor económico debe coincidir
                          con la información de la persona que se ha presentado.
                          Si no coincide, es necesario acudir a la
                          administración correspondiente.
                        </li>
                        <li>
                          Debe presentar comprobante de la libreta de sellos
                          completa (paso 0)
                        </li>
                        <li>
                          Verificar que el nombre del grado y la especialidad a
                          cursar sea correcto.
                        </li>
                        <li>
                          No debe tener pagos del año lectivo 2024 pendientes.
                        </li>
                        <li>
                          Comprobar que se haya completado el pago de la reserva
                          y el complemento, o únicamente el pago de la
                          matrícula.
                        </li>
                      </ul>
                    </v-col>
                  </v-row>
                  <!-- end:: Indicaciones del procedimiento a realizar por las personas designadas -->

                  <v-row v-if="formStatus != null">
                    <v-col>
                      <v-alert
                        prominent
                        :type="formStatus == 7 ? 'success' : 'warning'"
                      >
                      <h3>
                        Información sobre el formulario de información del estudiante
                      </h3>
                        {{
                          formStatus == 7
                            ? "Formulario de actualización de datos completado"
                            : "El formulario de actualización de datos no ha sido completado en su totalidad"
                        }}
                      </v-alert>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="5">
                      <v-card outlined class="pa-5 pb-3 rounded-lg elevation-0">
                        <v-scroll-y-reverse-transition
                          :hide-on-leave="true"
                          :leave-absolute="true"
                        >
                          <div v-if="isLoadingEconomicManager" class="pa-5">
                            <v-row class="d-flex align-center flex-column">
                              <p class="text-h5 font-weight-bold mb-3">
                                Información del sostenedor económico
                              </p>
                              <p class="text-subtitle font-weight-bold mb-3">
                                Cargando información...
                              </p>

                              <v-col cols="6">
                                <v-progress-linear
                                  color="blue darken-1"
                                  height="6"
                                  indeterminate
                                  rounded
                                ></v-progress-linear>
                              </v-col>
                            </v-row>
                          </div>
                        </v-scroll-y-reverse-transition>

                        <v-scroll-y-reverse-transition
                          :hide-on-leave="true"
                          :leave-absolute="true"
                        >
                          <div v-if="!isLoadingEconomicManager">
                            <v-row v-if="existEconomicManager">
                              <v-col class="d-flex justify-start flex-column">
                                <v-row>
                                  <v-col cols="12">
                                    <p class="text-h5 font-weight-bold mb-3">
                                      Información de sostenedor económico
                                    </p>
                                  </v-col>
                                  <v-col
                                    class="d-flex flex-row-reverse justify-space-between"
                                    cols="12"
                                  >
                                    <div>
                                      <v-avatar
                                        color="green lighten-4"
                                        size="80"
                                      >
                                        <v-icon color="success" large
                                          >mdi-account-outline
                                        </v-icon>
                                      </v-avatar>
                                    </div>

                                    <div
                                      class="mr-md-3 d-flex flex-column justify-center"
                                    >
                                      <p
                                        class="font-weight-medium mb-0 text-h6 text-capitalize"
                                      >
                                        {{ studentEconomicManager.first_name }}
                                      </p>
                                      <p
                                        class="font-weight-medium mb-0 text-h6 text-capitalize"
                                      >
                                        {{ studentEconomicManager.last_name }}
                                      </p>
                                    </div>
                                  </v-col>
                                </v-row>
                                <!-- ----------------------------- -->
                                <v-row>
                                  <v-col cols="12">
                                    <p class="font-weight-bold mb-0 text-h6">
                                      Nacionalidad:
                                    </p>
                                    <p
                                      class="font-weight-medium mb-0 text-body-1"
                                    >
                                      {{ studentEconomicManager.nationality }}
                                    </p>
                                  </v-col>
                                  <v-col cols="12">
                                    <p class="font-weight-bold mb-0 text-h6">
                                      {{
                                        studentEconomicManager.nationality ===
                                          "Salvadoreño/a" &&
                                        studentEconomicManager.dui?.length == 10
                                          ? "DUI"
                                          : "Número de documento de identidad"
                                      }}
                                    </p>
                                    <p
                                      class="font-weight-medium mb-0 text-body-1"
                                    >
                                      {{ studentEconomicManager.dui }}
                                    </p>
                                  </v-col>
                                  <v-col cols="12">
                                    <p class="font-weight-bold mb-0 text-h6">
                                      Número de teléfono:
                                    </p>
                                    <p
                                      class="font-weight-medium mb-0 text-body-1"
                                    >
                                      {{ studentEconomicManager.cellphone }}
                                    </p>
                                  </v-col>
                                  <v-col cols="12">
                                    <p class="font-weight-bold mb-0 text-h6">
                                      Correo electrónico:
                                    </p>
                                    <p
                                      class="font-weight-medium mb-0 text-body-1"
                                    >
                                      {{ studentEconomicManager.email }}
                                    </p>
                                  </v-col>
                                  <v-col cols="12">
                                    <p class="font-weight-bold mb-0 text-h6">
                                      Dirección de residencia:
                                    </p>
                                    <p
                                      class="font-weight-medium mb-0 text-body-1"
                                    >
                                      {{ studentEconomicManager.address }}.
                                      {{
                                        studentEconomicManager.municipality
                                          .departamento.nombre
                                      }},
                                      {{
                                        studentEconomicManager.municipality
                                          .nombre
                                      }}
                                    </p>
                                  </v-col>
                                </v-row>
                              </v-col>
                            </v-row>

                            <v-row
                              v-if="!existEconomicManager"
                              class="d-flex flex-column align-center pa-4 mt-n4"
                            >
                              <p
                                class="text-h5 font-weight-bold mb-5 text-center"
                              >
                                Información de sostenedor económico
                              </p>
                              <v-avatar
                                class="mb-4"
                                color="red lighten-4"
                                size="80"
                              >
                                <v-icon color="red" large
                                  >mdi-account-outline
                                </v-icon>
                              </v-avatar>
                              <p class="text-center font-weight-medium text-h6">
                                No se ha registrado un responsable económico.
                              </p>
                            </v-row>

                            <v-divider></v-divider>
                            <!-- Oliver work -->
                            <div
                              class="d-flex justify-center align-center flex-column"
                            >
                              <v-btn
                                class="primary lighten-4 mb-3"
                                block
                                depressed
                                :disabled="isLoadingForm"
                                :loading="isLoadingForm"
                                @click="showEconomicManagerModel()"
                              >
                                <p class="mb-0 font-weight-bold primary--text">
                                  {{
                                    existEconomicManager
                                      ? "Editar información"
                                      : "Registrar sostenedor"
                                  }}
                                  <v-icon right>mdi-plus-circle</v-icon>
                                </p>
                              </v-btn>

                              <v-btn
                                v-if="isNotNewStudent"
                                block
                                :disabled="isEnablingForm || formStatus != 7"
                                :loading="isEnablingForm"
                                class="deep-purple lighten-4"
                                depressed
                                @click="enableFormForStudent()"
                              >
                                <p
                                  class="mb-0 font-weight-bold deep-purple--text"
                                >
                                  {{
                                    formStatus != 7
                                      ? "Formulario habilitado"
                                      : "Habilitar formulario"
                                  }}
                                  <v-icon right>mdi-account-outline</v-icon>
                                </p>
                              </v-btn>
                            </div>
                          </div>
                        </v-scroll-y-reverse-transition>
                      </v-card>
                    </v-col>

                    <v-col cols="7">
                      <v-card outlined class="pa-5 pb-3 rounded-lg elevation-0">
                        <h2>Listado de familiares registrados</h2>
                        <v-container>
                          <v-row>
                            <v-col
                              v-for="(item, index) in familyInfo"
                              :key="index"
                              cols="12"
                              lg="6"
                            >
                              <div
                                v-if="isNotNewStudent"
                                class="mb-2 light-blue lighten-5 px-4 py-3 rounded-lg"
                              >
                                <h3 class="mb-0 grey--text text--darken-3 mt-1">
                                  {{ `${item.firstName} ${item.lastName}` }}
                                </h3>
                                <p
                                  class="mb-0 grey--text text--darken-3 text-body-1 mt-1"
                                >
                                  Parentesco:
                                  <span class="font-weight-bold">{{
                                    item.Kinship.name
                                  }}</span>
                                </p>
                                <p
                                  class="mb-0 grey--text text--darken-3 text-body-1 mt-1"
                                >
                                  DUI:
                                  <span class="font-weight-bold">{{
                                    item.dui
                                  }}</span>
                                </p>
                                <p
                                  class="mb-0 grey--text text--darken-3 text-body-1 mt-1"
                                >
                                  Célular:
                                  <span class="font-weight-bold">{{
                                    item.cellphone
                                  }}</span>
                                </p>
                              </div>

                              <div
                                v-else
                                class="mb-2 light-blue lighten-5 px-4 py-3 rounded-lg"
                              >
                                <h3 class="mb-0 grey--text text--darken-3 mt-1">
                                  {{ `${item.nombres} ${item.apellidos}` }}
                                </h3>
                                <p
                                  class="mb-0 grey--text text--darken-3 text-body-1 mt-1"
                                >
                                  Parentesco:
                                  <span class="font-weight-bold">{{
                                    item.parentesco
                                  }}</span>
                                </p>
                                <p
                                  class="mb-0 grey--text text--darken-3 text-body-1 mt-1"
                                >
                                  DUI:
                                  <span class="font-weight-bold">{{
                                    item.dui
                                  }}</span>
                                </p>
                                <p
                                  class="mb-0 grey--text text--darken-3 text-body-1 mt-1"
                                >
                                  Célular:
                                  <span class="font-weight-bold">{{
                                    item.celular
                                  }}</span>
                                </p>
                              </div>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <v-card outlined class="pa-5 pr-8 rounded-lg elevation-0">
                        <v-scroll-y-reverse-transition
                          :hide-on-leave="true"
                          :leave-absolute="true"
                        >
                          <div v-if="isLoadingPayments" class="pa-5">
                            <v-row class="d-flex align-center flex-column">
                              <p class="text-h5 font-weight-bold mb-3">
                                Historial de pagos
                              </p>

                              <p class="text-subtitle font-weight-bold mb-3">
                                Cargando información...
                              </p>

                              <v-col cols="6">
                                <v-progress-linear
                                  color="blue darken-1"
                                  height="6"
                                  indeterminate
                                  rounded
                                ></v-progress-linear>
                              </v-col>
                            </v-row>
                          </div>
                        </v-scroll-y-reverse-transition>

                        <v-scroll-y-reverse-transition
                          :hide-on-leave="true"
                          :leave-absolute="true"
                        >
                          <div v-if="!isLoadingPayments">
                            <div v-if="studentPaymentsHistory.length === 0">
                              <v-row
                                class="d-flex flex-column align-center mt-1"
                              >
                                <v-col cols="12">
                                  <v-alert
                                    v-if="hasPendingPayments == 1"
                                    prominent
                                    type="error"
                                  >
                                    <h2>¡Atención!</h2>
                                    Tiene pagos pendientes del año lectivo 2024.
                                    Debe pasar a cancelar al paso 2.
                                  </v-alert>
                                </v-col>

                                <p class="text-h5 font-weight-bold mb-3">
                                  Historial de pagos realizados
                                </p>

                                <v-col
                                  class="d-flex align-center flex-column"
                                  cols="8"
                                >
                                  <v-avatar size="80" color="amber lighten-5">
                                    <v-icon color="amber darken-1">
                                      mdi-currency-usd-off
                                    </v-icon>
                                  </v-avatar>
                                  <p class="text-h6 font-weight-medium mt-4">
                                    No se ha cancelado ningún pago.
                                  </p>
                                </v-col>
                              </v-row>
                            </div>

                            <!-- begin:: Listado de pagos realizados -->
                            <div v-else>
                              <p class="text-h5 font-weight-bold mb-3">
                                Pagos realizados
                              </p>

                              <v-alert
                                v-if="hasPendingPayments == 1"
                                prominent
                                type="error"
                              >
                                <h2>¡Atención!</h2>
                                Tiene pagos pendientes del año lectivo 2024.
                                Debe pasar a cancelar al paso 2.
                              </v-alert>

                              <v-timeline dense clipped class="pt-10">
                                <v-timeline-item
                                  v-for="(
                                    payment, index
                                  ) in studentPaymentsHistory"
                                  :key="index"
                                  class="mb-2"
                                  color="primary lighten-4"
                                  fill-dot
                                  large
                                >
                                  <template v-slot:icon>
                                    <v-icon color="primary"
                                      >mdi-receipt-text-check-outline
                                    </v-icon>
                                  </template>

                                  <p
                                    v-if="payment.VALOR_PAGADO"
                                    class="black--text text-md-h6 font-weight-normal mb-0"
                                  >
                                    <span
                                      class="font-weight-bold black--text text--darken-4"
                                    >
                                      {{ payment.COMENTARIO }}
                                    </span>
                                    por un monto de
                                    <span
                                      class="font-weight-bold success--text text--darken-1"
                                      >{{
                                        toUSDFormat(payment.VALOR_PAGADO)
                                      }}</span
                                    >
                                    dólares.
                                  </p>
                                  <p
                                    v-else
                                    class="black--text text-md-h6 font-weight-normal mb-0"
                                  >
                                    <span
                                      class="font-weight-bold black--text text--darken-4"
                                    >
                                      {{ payment.COMENTARIO }}
                                    </span>
                                    pago realizado en línea pendiente de ser
                                    facturado
                                  </p>
                                  <p
                                    v-if="payment.VALOR_PAGADO"
                                    class="mb-0 grey--text text--darken-3 text-body-1 mt-1"
                                  >
                                    N.° de documento de pago:
                                    <span class="font-weight-bold">{{
                                      payment.NUME_DOCU_PAGO
                                    }}</span>
                                  </p>
                                </v-timeline-item>
                              </v-timeline>
                            </div>
                            <!-- end:: Listado de pagos realizados -->
                          </div>
                        </v-scroll-y-reverse-transition>
                      </v-card>
                    </v-col>
                  </v-row>
                  <!-- end::step content -->

                  <v-row v-if="false">
                    <v-col cols="12">
                      <v-alert dense prominent text type="info">
                        <div
                          class="d-flex flex-column flex-sm-row justify-space-between align-center"
                        >
                          <h3>Confirmar información de sostenedor ecońomico</h3>
                          <v-btn class="rounded-lg" color="primary">
                            Confirmar
                          </v-btn>
                        </div>
                      </v-alert>
                    </v-col>
                    <v-col cols="12">
                      <v-alert dense prominent text type="info">
                        <div
                          class="d-flex flex-column flex-sm-row justify-space-between align-center"
                        >
                          <h3>Confirmar verificación de pagos realizados</h3>
                          <v-btn class="rounded-lg" color="primary">
                            Confirmar
                          </v-btn>
                        </div>
                      </v-alert>
                    </v-col>
                  </v-row>

                  <!-- begin:: footer - step 1 -->
                  <v-row
                    v-if="
                      !isLoadingEnrollmentProcessState &&
                      enrollmentProcessState != null
                    "
                  >
                    <v-col cols="12">
                      <v-scroll-y-reverse-transition
                        :hide-on-leave="true"
                        :leave-absolute="true"
                      >
                        <v-alert
                          v-if="
                            getStepOneInfo === undefined &&
                            existEconomicManager &&
                            hasAllPayments
                          "
                          dense
                          prominent
                          text
                          type="info"
                        >
                          <div
                            class="pa-5 rounded-lg d-flex flex-column flex-sm-row justify-space-between align-center mb-0"
                          >
                            <div>
                              <p class="font-weight-bold mb-1 text-h6">
                                Finalizar paso 1
                              </p>
                              <p class="font-weight-medium text-body-1">
                                Al hacer click en
                                <span class="font-weight-bold">"Confirmar"</span
                                >, confirmas que los datos del
                                <span class="font-weight-bold"
                                  >sostenedor económico</span
                                >
                                son correctos y vigentes. También, aseguras que
                                los pagos de
                                <span class="font-weight-bold"
                                  >matrícula y curso de inducción</span
                                >
                                han sido realizados.
                              </p>
                            </div>

                            <v-btn
                              :disabled="enrollmentProcessStateIsBeingUpdated"
                              :isLoading="enrollmentProcessStateIsBeingUpdated"
                              class="rounded-lg"
                              color="primary"
                              depressed
                              x-large
                              @click="saveEnrollmentProcessState(1)"
                            >
                              <p class="white--text mb-0 font-weight-bold">
                                Confirmar
                                <v-icon right>mdi-account-check</v-icon>
                              </p>
                            </v-btn>
                          </div>
                        </v-alert>
                      </v-scroll-y-reverse-transition>

                      <v-scroll-y-reverse-transition
                        :hide-on-leave="true"
                        :leave-absolute="true"
                      >
                        <v-alert
                          v-if="
                            (getStepOneInfo === undefined &&
                              !existEconomicManager) ||
                            (getStepOneInfo === undefined && !hasAllPayments)
                          "
                          class="rounded-lg"
                          dense
                          prominent
                          text
                          type="error"
                        >
                          <div
                            class="pa-5 rounded-lg d-flex flex-column flex-sm-row justify-space-between align-center mb-0"
                          >
                            <div>
                              <p class="font-weight-bold mb-1 text-h6">
                                ¡Faltan datos!
                              </p>
                              <p class="font-weight-medium text-body-1">
                                No se puede continuar con el proceso debido a
                                que
                                <span class="font-weight-bold"
                                  >Hace falta registrar un a sostenedor
                                  económico del estudiante</span
                                >
                                ó
                                <span class="font-weight-bold"
                                  >no se ha cancelado la matrícula, el curso de
                                  inducción o ambos</span
                                >.
                              </p>
                            </div>
                          </div>
                        </v-alert>
                      </v-scroll-y-reverse-transition>

                      <v-scroll-y-reverse-transition
                        :hide-on-leave="true"
                        :leave-absolute="true"
                      >
                        <v-alert
                          v-if="getStepOneInfo !== undefined"
                          class="rounded-lg"
                          dense
                          prominent
                          text
                          type="success"
                        >
                          <div
                            class="pa-5 rounded-lg d-flex flex-row justify-space-between align-center mb-0"
                          >
                            <div>
                              <p class="font-weight-bold mb-1 text-h6">
                                Información del sostenedor económico y pagos
                                verificada
                              </p>
                              <p class="font-weight-medium text-body-1">
                                Información verificada por
                                <span class="font-weight-bold">
                                  {{ getStepOneInfo.user_full_name }}
                                </span>
                                el
                                <span class="font-weight-bold">
                                  {{ getStepOneInfo.created_at_formatted }}
                                </span>
                              </p>
                            </div>
                          </div>
                        </v-alert>
                      </v-scroll-y-reverse-transition>
                    </v-col>
                  </v-row>
                </v-stepper-content>

                <v-stepper-content step="2">
                  <v-row v-if="isNewStudent">
                    <v-col cols="12">
                      <h2>Registro y control de documentos presentados</h2>
                      <p class="text-body-1">
                        Marcar las casillas de los documentos que han
                        presentado. En dado caso falte uno dejar la casilla sin
                        marcar.
                      </p>
                    </v-col>
                    <v-col class="12">
                      <!--Documents checks-->
                      <v-card class="mb-5" elevation="0" outlined>
                        <v-list>
                          <v-list-item-group>
                            <v-list-item
                              active-class="primary--text text--accent-4"
                            >
                              <v-list-item-content>
                                <v-list-item-title class="text-h5"
                                  >Certificado de notas
                                </v-list-item-title>
                              </v-list-item-content>

                              <v-list-item-action>
                                <v-checkbox
                                  v-model="documents.scores"
                                  color="primary accent-4"
                                ></v-checkbox>
                              </v-list-item-action>
                            </v-list-item>
                            <v-divider></v-divider>
                            <v-list-item
                              active-class="primary--text text--accent-4"
                            >
                              <v-list-item-content>
                                <v-list-item-title class="text-h5"
                                  >Partida de nacimiento
                                </v-list-item-title>
                              </v-list-item-content>

                              <v-list-item-action>
                                <v-checkbox
                                  v-model="documents.birth_certificate"
                                  color="primary accent-4"
                                ></v-checkbox>
                              </v-list-item-action>
                            </v-list-item>
                          </v-list-item-group>
                        </v-list>
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <!--Generate report card-->
                      <v-card
                        class="pa-5 pr-8 rounded-lg elevation-0 mb-5"
                        outlined
                      >
                        <IconCardWithActionButton
                          colorTheme="deep-purple"
                          icon="file-document"
                          subTitle="Generar documento de sostenedor económico, carta de compromiso de entrega de documentos pendientes en caso le falte alguno, contrato y pagaré"
                          title="Generar documentos"
                          :loading="loadingDocuments"
                          buttonLegend="Generar documentos"
                          @action="generateGroupOfDocuments()"
                        ></IconCardWithActionButton>
                      </v-card>
                    </v-col>
                  </v-row>

                  <!-- begin:: footer - step 1 -->
                  <v-row
                    v-if="
                      !isLoadingEnrollmentProcessState &&
                      enrollmentProcessState != null
                    "
                  >
                    <v-col cols="12">
                      <v-alert
                        v-if="getStepTwoInfo === undefined"
                        dense
                        prominent
                        text
                        type="info"
                      >
                        <div
                          class="pa-5 rounded-lg d-flex flex-column flex-sm-row justify-space-between align-center mb-0"
                        >
                          <div>
                            <p class="font-weight-bold mb-1 text-h6">
                              Finalizar paso 2
                            </p>
                            <p class="font-weight-medium text-body-1">
                              Al hacer click en
                              <span class="font-weight-bold">"Confirmar"</span>,
                              confirmas que has verificado los documentos que
                              debían presentar y que los documentos impresos han
                              sido firmados.
                            </p>
                          </div>

                          <v-btn
                            :disabled="
                              enrollmentProcessStateIsBeingUpdated ||
                              getStepOneInfo === undefined
                            "
                            :isLoading="enrollmentProcessStateIsBeingUpdated"
                            color="primary"
                            depressed
                            x-large
                            @click="saveEnrollmentProcessState(2)"
                          >
                            <p class="white--text mb-0 font-weight-bold">
                              Confirmar
                              <v-icon right>mdi-account-check</v-icon>
                            </p>
                          </v-btn>
                        </div>
                      </v-alert>
                      <v-alert
                        v-else
                        class="rounded-lg"
                        dense
                        prominent
                        text
                        type="success"
                      >
                        <div
                          class="pa-5 rounded-lg d-flex flex-row justify-space-between align-center mb-0"
                        >
                          <div>
                            <p class="font-weight-bold mb-1 text-h6">
                              Documentos revisados, impresos y firmados
                            </p>
                            <p class="font-weight-medium text-body-1">
                              Información verificada por
                              <span class="font-weight-bold">
                                {{ getStepTwoInfo.user_full_name }}
                              </span>
                              el
                              <span class="font-weight-bold">
                                {{ getStepTwoInfo.created_at_formatted }}
                              </span>
                            </p>
                          </div>
                        </div>
                      </v-alert>
                    </v-col>
                  </v-row>
                </v-stepper-content>
              </v-stepper-items>
              <!-- --------------------------- -->
            </v-stepper>

            <!-- end::Steps -->
          </v-container>
        </div>
      </v-scroll-y-reverse-transition>
      <!-- end:: Información de estudiante si hay un estudiante seleccionado -->

      <!-- begin:: Mensaje que se muestra si no hay un estudiante seleccionado -->
      <v-scroll-y-reverse-transition
        :hide-on-leave="true"
        :leave-absolute="true"
      >
        <div v-if="!showStepper">
          <v-card class="pa-10 elevation-0">
            <v-row class="d-flex align-center flex-column">
              <v-col cols="6" class="d-flex align-center flex-column">
                <v-avatar color="red lighten-4" size="100">
                  <v-icon color="red">mdi-account-group-outline</v-icon>
                </v-avatar>
                <p class="mt-4 text-h6 font-weight-medium text-center">
                  Para continuar con el proceso de matrícula, por favor
                  <span class="font-weight-bold"
                    >digite un código de estudiante</span
                  >, busque por <span class="font-weight-bold">nombre</span> ó
                  <span class="font-weight-bold">apellido</span>.
                </p>
              </v-col>
            </v-row>
          </v-card>
        </div>
      </v-scroll-y-reverse-transition>
      <!-- end:: Mensaje que se muestra si no hay un estudiante seleccionado -->

      <!--begin::Start economic manager model-->
      <RegisterStudentEconomicManagerModal
        ref="RegisterStudentEconomicManagerModal"
        :economicManagerInfo="studentEconomicManager"
        :refreshStudentManager="getStudentEconomicManager"
        :studentInformation="student"
      >
      </RegisterStudentEconomicManagerModal>
      <!--end::end economic manager model-->

      <EnrollmentErrorModal
        ref="enrollmentErrorModal"
        :providedError="providedError"
      ></EnrollmentErrorModal>
      <CouldNotSetFirstStepInStudentFormErrorModal
        ref="couldNotSetFirstStepModal"
      ></CouldNotSetFirstStepInStudentFormErrorModal>
      <!-- end: Student Information -->
    </div>
  </div>
  <!-- end::Card -->
</template>

<script>
import Swal from "sweetalert2";

import studentRepository from "@/repositories/studentRepository";
import onlinePaymentRepository from "@/repositories/onlinePaymentRepository";
import enrollmentProcessRepository from "@/repositories/enrollmentProcessRepository";
import RegisterStudentEconomicManagerModal from "@/components/modals/students/RegisterStudentEconomicManagerModal";
import IconCardWithActionButton from "@/components/elements/dashboard/IconCardWithAction.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import {
  GET_CURRENT_PAGE_ACTIONS,
  PURGE_CURRENT_PAGE_ACTIONS,
} from "@/core/services/store/role.module";
import EnrollmentErrorModal from "@/components/modals/enrollment_process/EnrollmentErrorModal.vue";
import CouldNotSetFirstStepInStudentFormErrorModal from "@/components/modals/enrollment_process/CouldNotSetFirstStepInStudentFormErrorModal.vue";
import Axios from "axios";
import studentEnrollmentTrackingRepository from "../../../repositories/studentEnrollmentTrackingRepository";

export default {
  name: "EnrollmentProcesses",
  title: "Proceso de matrícula | GE ITR",

  data() {
    return {
      providedError: "",
      isLoadingStudentInfo: false,
      isLoadingEconomicManager: false,
      isLoadingPayments: false,
      search: "",
      showStepper: false,
      student: {},
      studentUUID: null,
      studentEnrollmentVerificationStatus: {},
      confirmButtonIsLoading: false,
      verifyingEconomicManagerExistence: false,
      existEconomicManager: false,
      studentEconomicManager: {},
      activeState: 1,
      students: [],
      areStudentsLoading: false,
      filteredStudent: [],
      studentPaymentsHistory: false,

      documents: {
        scores: false,
        birth_certificate: false,
      },
      enrollmentProcessState: null,
      enrollmentProcessStateIsBeingUpdated: false,
      isLoadingEnrollmentProcessState: false,
      familyInfo: [],
      loadingDocuments: false,
      isLoadingForm: false,
      isEnablingForm: false,
      formStatus: null,
      hasPendingPayments: null,

      studentEnrollmentTracking: {
        data: [],
        isLoading: false,
      },
    };
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Proceso de matrícula", route: "enrollment_processes" },
    ]);
    this.$store.dispatch(GET_CURRENT_PAGE_ACTIONS, "Proceso de matrícula");
    this.getStudents();
  },

  components: {
    CouldNotSetFirstStepInStudentFormErrorModal,
    EnrollmentErrorModal,
    IconCardWithActionButton,
    RegisterStudentEconomicManagerModal,
  },

  methods: {
    setStepGivenPermission() {
      if (
        this.existInArray(
          "Verificar información del sostenedor económico y pagos",
          this.currentPageActions
        )
      ) {
        this.activeState = 1;
      } else if (
        this.existInArray(
          "Verificar e imprimir documentos",
          this.currentPageActions
        )
      ) {
        this.activeState = 2;
      }
    },
    getStudents() {
      this.areStudentsLoading = true;
      studentRepository
        .getEnrollableStudents()
        .then(({ data }) => {
          this.students = data;
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        })
        .finally(() => {
          this.areStudentsLoading = false;
        });
    },

    getStudentEnrollmentTrackingBySelectedStudent(studentUuid) {
      this.studentEnrollmentTracking.isLoading = true;

      studentEnrollmentTrackingRepository
        .getStudentEnrollmentTrackingByStudentUuid(studentUuid)
        .then(({ data }) => {})
        .catch((error) => {
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
          console.error(error?.message);
        })
        .finally(() => {
          this.studentEnrollmentTracking.isLoading = false;
        });
    },

    storeStudentEnrollmentTracking({
      studentUuid,
      admissionYear,
      step,
      notes,
      userId,
    }) {
      const payload = {
        student_uuid: studentUuid,
        step,
        notes,
        user_id: userId,
        admission_year: admissionYear,
      };
      studentEnrollmentTrackingRepository
        .createStudentEnrollmentTracking(payload)
        .then((result) => {
          this.getStudentEnrollmentTrackingBySelectedStudent(studentUuid);
        })
        .catch((err) => {
          console.error(err);
        });
    },

    resetEconomicManagerInformation() {
      this.studentEconomicManager = {};
      this.familyInfo = [];
      this.existEconomicManager = false;
    },

    getStudent() {
      const code = this.search;

      this.resetEconomicManagerInformation();

      //Check if a valid code exists
      if (!code) {
        this.showStepper = false;
        return;
      }

      this.showStepper = true;

      // reset al variables and make request.

      this.isLoadingStudentInfo = true;
      this.isLoadingEconomicManager = true;
      this.isLoadingPayments = true;
      this.student = {};
      this.studentUUID = null;
      this.formStatus = null;
      this.hasPendingPayments = null;

      studentRepository
        .getStudentEnrollmentByCode(code)
        .then(({ data }) => {
          if (!data.status) {
            this.resetEconomicManagerInformation();
            this.sweetAlertResponse({
              status: false,
              message: data.message,
            });
          } else {
            /*this request is still being made because it provides
              the textual student details, like speciality, uuid, grade and
              not only the respective IDs.*/
            this.student = data.data;

            this.resetEconomicManagerInformation();
            /**
             * Al hacer clic en un estudiante
             * 1. Se obtiene la información del sostenedor económico de la API de ge-students. Puede venir info o venir null
             * 2. Se obtiene las confirmación de pasos completados EnrollmentProcess para hacer previas validaciones en lo que se muestra
             * 3. Se obtienen el historial de pagos relacionados a matricula segun el año de ejercicio especificado
             * 4. Se obtiene la información de los familiares dependiendo de si es de nuevo ingreso los obtiene de admisión:solicitante_responsable; si no, los obtiene de form_api
             * 5. Se obtiene el step en el que se quedarón las personas para llenar el formulario de actualización de datos para saber si esta completo
             * 6. Se obtienen los pagos de los años anteriores para ver si tiene pagos pendientes
             */
            this.getStudentEconomicManager(this.student.uuid);
            this.getEnrollmentProcessState(this.student.uuid);
            this.getStudentEnrollmentTrackingBySelectedStudent(
              this.student.uuid
            );

            const anioAConsultarDePagosRealizados =
              process.env.YEAR_OF_ADMISSION_ENROLLMENT_PROCESS;
            this.getStudentPaymentHistory(
              this.student.code,
              anioAConsultarDePagosRealizados
            );

            this.getFamilyInfo(this.student.code, this.student.uuid);
            this.getStudentFormStatus(this.student.uuid);

            const anioAConsultarDePagosPendientes = 2024;
            this.getPagosPendientesDelEstudiantePorAnio(
              this.student.code,
              anioAConsultarDePagosPendientes
            );
          }
        })
        .catch(() => {
          // handle errors
          this.fireToast({
            icon: "error",
            title:
              "No ha sido posible cargar los datos del estudiante desde el servidor",
          });
          this.resetEconomicManagerInformation();
        })
        .finally(() => {
          this.isLoadingStudentInfo = false;
        });
    },

    getStudentFormStatus(studentUUID) {
      Axios.get(`${this.formApiUrl}/students/step/${studentUUID}`)
        .then(({ data }) => {
          if (data != null) {
            this.formStatus = data.formStatus;
          } else {
            this.formStatus = null;
          }
        })
        .catch((err) => {
          console.error(
            "Error al obtener el estado del formulario" + err.message
          );
        });
    },

    getPagosPendientesDelEstudiantePorAnio(studentCode, anioAConsultar) {
      onlinePaymentRepository
        .getPagosPendientesPorAnio(studentCode, anioAConsultar)
        .then(({ data }) => {
          if (data !== null) {
            this.hasPendingPayments = data;
          } else {
            this.hasPendingPayments = null;
          }
        })
        .catch((err) => {
          console.error(
            "Error al obtener el estado del formulario" + err.message
          );
        });
    },

    getStudentEconomicManager(studentUUID) {
      this.isLoadingEconomicManager = true;

      this.studentEconomicManager = {};

      onlinePaymentRepository
        .registeredStudentEconomicManager(studentUUID)
        .then(({ data }) => {
          this.studentEconomicManager = data;

          if (Object.keys(this.studentEconomicManager).length === 0) {
            this.studentEconomicManager.uuid = studentUUID;
            // this.openRegisterStudentEconomicManagerModal();
            this.studentEconomicManager = {};
            this.existEconomicManager = false;
          } else {
            this.verifyingEconomicManagerExistence = false;
            this.existEconomicManager = true;
          }
        })
        .catch(() => {
          this.studentEconomicManager = {};
          this.fireToast({
            icon: "error",
            title: "No se pudo traer al responsable económico",
          });
        })
        .finally(() => {
          this.isLoadingEconomicManager = false;
        });
    },

    customFilter(item, queryText) {
      const searchText = queryText.toLowerCase();
      const codeMatch = item.code.toString().includes(searchText);
      const fullNameMatch = item.full_name.toLowerCase().includes(searchText);
      return codeMatch || fullNameMatch;
    },

    getStudentPaymentHistory(code, anioDeEjercicioAConsultar) {
      onlinePaymentRepository
        .getAllPaidPaymentsAdmissionCheck(code, anioDeEjercicioAConsultar)
        .then(({ data }) => {
          this.studentPaymentsHistory = data;
        })
        .catch(() => {})
        .finally(() => {
          this.isLoadingPayments = false;
        });
    },

    getEnrollmentProcessState(studentUUID) {
      this.isLoadingEnrollmentProcessState = true;
      enrollmentProcessRepository
        .getEnrollmentProcessOfStudent(studentUUID)
        .then(({ data }) => {
          this.enrollmentProcessState = data;
          if (data.length === 1) {
            this.activeState = 2;
          }

          this.setStepGivenPermission();
        })
        .catch((err) => {
          console.error(err.message);
        })
        .finally(() => {
          this.isLoadingEnrollmentProcessState = false;
        });
    },

    generateGroupOfDocuments() {
      this.loadingDocuments = true;

      enrollmentProcessRepository
        .generateGroupOfDocuments(this.student.code, {
          documents: this.documents,
          user: this.currentUserPersonalInfo.user,
        })
        .then(({ data }) => {
          const pdfBlob = new Blob([data], { type: "application/pdf" });

          // Create a link element and trigger a download
          const downloadLink = document.createElement("a");
          downloadLink.href = window.URL.createObjectURL(pdfBlob);
          downloadLink.download = `${this.student.code}_documentos.pdf`; // Change 'output.pdf' to the desired filename
          downloadLink.click();
        })
        .catch((err) => {
          this.providedError = err.message;
          this.openEnrollmentErrorModal();
        })
        .finally(() => {
          this.loadingDocuments = false;
        });
    },

    openEnrollmentErrorModal() {
      this.$refs.enrollmentErrorModal.toggleModal();
    },

    openFirstStepFailErrorModal() {
      this.$refs.couldNotSetFirstStepModal.toggleModal();
    },

    getFamilyInfo(studentCode, studentUUID) {
      enrollmentProcessRepository
        .getFamilyMembers(studentCode, studentUUID)
        .then(({ data }) => {
          this.familyInfo = data;
        })
        .catch((err) => {
          console.error(err.message);
        });
    },

    saveEnrollmentProcessState(step) {
      this.enrollmentProcessStateIsBeingUpdated = true;
      Swal.fire({
        title: "¿Estás seguro de que deseas confirmar?",
        showCancelButton: true,
        confirmButtonText: "Sí, confirmar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          const payload = {
            step,
            student_uuid: this.student.uuid,
            user_id: this.currentUserPersonalInfo.id_user,
          };
          enrollmentProcessRepository
            .createEnrollmentProcess(payload)
            .then(({ data }) => {
              this.sweetAlertResponse(data);
              this.getEnrollmentProcessState(this.student.uuid);
            })
            .catch((err) => {
              console.error(err.message);
            })
            .finally(() => {
              this.enrollmentProcessStateIsBeingUpdated = false;
            });
        } else if (result.isDismissed) {
          this.enrollmentProcessStateIsBeingUpdated = false;
        }
      });
    },
    showEconomicManagerModel() {
      if (this.isNotNewStudent) {
        this.isLoadingForm = true;

        const setStepUrl = "https://apiform.ricaldone.edu.sv/api/students/step";

        const payload = {
          formStatus: 0,
        };

        fetch(setStepUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${this.student.uuid}`,
          },
          body: JSON.stringify(payload),
        })
          .then(() => {
            //redirecting to the student form to update economic manager
            window.open(
              `https://form.ricaldone.edu.sv/#/student/${this.student.uuid}`,
              "_blank"
            );

            //Cleaning student info, so it can be re-fetched after making modifications in the student form
            this.search = null;
            this.showStepper = false;
            this.student = {};
            this.studentUUID = null;
          })
          .catch((error) => {
            this.openFirstStepFailErrorModal();
            console.warn("Error:", error);
          })
          .finally(() => {
            this.isLoadingForm = false;
          });
        return;
      }
      this.$refs.RegisterStudentEconomicManagerModal.toggleModal();
    },

    enableFormForStudent() {
      Swal.fire({
        title: "¿Estas seguro?",
        text: "Al confirmar el formulario se habilitará y tendran que finalizarlo para poder continuar",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí, habilitarlo",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          this.isEnablingForm = true;
          const setStepUrl =
            "https://apiform.ricaldone.edu.sv/api/students/step";

          const payload = {
            formStatus: 0,
          };

          fetch(setStepUrl, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `${this.student.uuid}`,
            },
            body: JSON.stringify(payload),
          })
            .then(() => {
              this.fireToast({
                icon: "success",
                title: "Se habilitó el formulario",
                timer: 7000,
              });
              this.getStudentFormStatus(this.student.uuid);
            })
            .catch((error) => {
              this.fireToast({
                icon: "error",
                title:
                  "No se pudo habilitar el formulario, remita el caso a soporte IT",
                timer: 10000,
              });

              console.warn("Error:", error);
            })
            .finally(() => {
              this.isEnablingForm = false;
            });
        }
      });
    },
  },

  computed: {
    ...mapGetters(["currentPageActions"]),
    ...mapGetters(["currentUserPersonalInfo"]),

    hasAllPayments() {
      return true;
    },

    isNotNewStudent() {
      if (this.student?.code === undefined) {
        return "";
      }

      const code = this.student.code.toString();
      const YEAR_OF_ADMISSION_ENROLLMENT_PROCESS =
        process.env.YEAR_OF_ADMISSION_ENROLLMENT_PROCESS;
      return code.substring(0, 4) !== YEAR_OF_ADMISSION_ENROLLMENT_PROCESS;
    },

    isNewStudent() {
      if (this.student?.code === undefined) {
        return "";
      }

      const code = this.student.code.toString();
      const YEAR_OF_ADMISSION_ENROLLMENT_PROCESS =
        process.env.YEAR_OF_ADMISSION_ENROLLMENT_PROCESS;
      return code.substring(0, 4) === YEAR_OF_ADMISSION_ENROLLMENT_PROCESS;
    },

    getStepOneInfo() {
      return this.enrollmentProcessState.find((item) => item.step === 1);
    },

    getStepTwoInfo() {
      return this.enrollmentProcessState.find((item) => item.step === 2);
    },

    formApiUrl() {
      let baseURL;
      if (process.env.APP_ENV === "production") {
        baseURL = process.env.ADMISION_API_URL;
      } else if (process.env.APP_ENV === "development") {
        baseURL = process.env.ADMISION_DEV_API_URL;
      } else {
        baseURL = process.env.ADMISION_LOCAL_API_URL;
      }
      return baseURL;
    },
  },
  watch: {
    isLoadingStudentInfo(newValue) {
      if (newValue) {
        this.enrollmentProcessState = null;
        this.documents = {
          conduct: false,
          scores: false,
          birth_certificate: false,
        };
        this.familyInfo = null;
      }
    },
  },

  beforeDestroy() {
    this.$store.commit(PURGE_CURRENT_PAGE_ACTIONS);
  },
};
</script>
